import { debounce } from './debounce'

export default {
    data() {
        return {
            $_sidebarElm: null
        }
    },
    mounted() {
        this.$_initResizeEvent()
        this.$_initSidebarResizeEvent()
    },
    beforeDestroy() {
        this.$_destroyResizeEvent()
        this.$_destroySidebarResizeEvent()
    },
    activated() {
        this.$_initResizeEvent()
        this.$_initSidebarResizeEvent()
    },
    deactivated() {
        this.$_destroyResizeEvent()
        this.$_destroySidebarResizeEvent()
    },
    methods: {
        $_resizeHandler() {
            return debounce(() => {
                if (this.chart) {
                    this.chart.resize()
                }
            }, 0)()
        },
        $_initResizeEvent() {
            window.addEventListener('resize', this.$_resizeHandler)
        },
        $_destroyResizeEvent() {
            window.removeEventListener('resize', this.$_resizeHandler)
        },
        $_sidebarResizeHandler(e) {
            if (e.propertyName === 'width') {
                this.$_resizeHandler()
            }
        },
        $_initSidebarResizeEvent() {
            this.$_sidebarElm = document.getElementsByClassName('sidebar-container')[0]
            this.$_sidebarElm && this.$_sidebarElm.addEventListener('transitionend', this.$_sidebarResizeHandler)
        },
        $_destroySidebarResizeEvent() {
            this.$_sidebarElm && this.$_sidebarElm.removeEventListener('transitionend', this.$_sidebarResizeHandler)
        }
    }
}